import React from "react";
import { Col, CardBody, CardHeader } from "reactstrap";
import PageWrapper from "../PageWrapper";
import { Accordion, Button } from "react-bootstrap";
import CaretDown from "../../assets/img/caret-down-menu.svg";
import Check from "../../assets/img/check-circle-regular.svg";

import Emergencias from "../../assets/img/ua_emergencias_24.svg";
import Ambulancias from "../../assets/img/ua_ambulance.svg";
import Pildoras from "../../assets/img/ua_pildoras.svg";
import Avion from "../../assets/img/ua_plain.svg";
import IconInfo from "../../assets/img/info.svg";

import appuaa from "../../assets/img/playstore.png";
import appuai from "../../assets/img/apple.png";
import FaqsTitle from "../Faqs/FaqsTitle";

const ColoredLine = ({ color }) => (
  <hr
      style={{
          color: color,
          backgroundColor: color,
          height: 5
      }}
  />
);

const Content = () => {
  return (
    <PageWrapper left>
      <Col xs={12}>
        <h4 className="mb-4 text-blue">
          Viaje Protegido con la mejor asistencia
        </h4>
        <p>
        Los Afiliados de Alta Salud del plan A-10 Plus cuentan con la más completa cobertura de asistencia al viajero en la Argentina.
        Con un simple llamado telefónico usted podrá acceder al servicio más completo durante su viaje.
        </p>
        
        <div className="mb-3">
          <div className="iconos-ua-wrapper">
            <div className="iconos-ua-icon">
              <img src={IconInfo} className="icons_ua" alt="" />
            </div>
              Si desea consultar su cobertura, por favor ingrese &nbsp;
              <a className="links-inner-faqs text-blue ua-links" href="https://www.universal-assistance.com/ar-es/mi-cobertura.html" target="_blank">aquí</a>
          </div>
        </div>
        

        <div className="mb-3">
          <div className="iconos-ua-wrapper">
            <div className="iconos-ua-icon">
              <img src={IconInfo} className="icons_ua" alt="" />
            </div>
              Si está en viaje y desea solicitar asistencia, por favor ingrese &nbsp;
              <a className="links-inner-faqs text-blue ua-links" href="https://www.universal-assistance.com/ar-es/asistencia.html" target="_blank">aquí</a>
            </div>
        </div>


        <div className="mb-3">
          <div className="iconos-ua-wrapper">
            <div className="iconos-ua-icon">
              <img src={IconInfo} className="icons_ua" alt="" />
            </div>
              Si desea ampliar su cobertura ingrese &nbsp;
              <a className="links-inner-faqs text-blue ua-links" 
              href="https://www.universal-assistance.com/ar-es/affinity-partners?utm_source=santa_salud_ar_affinity&utm_medium=referral&utm_campaign=lp_santa_salud_arg" 
              target="_blank">aquí</a>
          </div>
        </div>
        
        <div className="mb-3">
          <div className="iconos-ua-wrapper">
              {/*
              <div className="iconos-ua-icon">
                <img src={IconInfo} className="icons_ua" alt="" /> 
              </div>
              */}
              Si usted es afiliado del plan C-60 Plus obtenga grandes descuentos en diferentes paquetes.
          </div>
        </div>

        <div className="mb-3">
          <div className="iconos-ua-wrapper">
            <div className="iconos-ua-icon">
              <img src={IconInfo} className="icons_ua" alt="" />
            </div>
              Si desea cotizar una cobertura, por favor ingrese &nbsp;
              <a className="links-inner-faqs text-blue ua-links" 
              href="https://www.universal-assistance.com/ar-es/affinity-partners?utm_source=santa_salud_ar_affinity&utm_medium=referral&utm_campaign=lp_santa_salud_arg" 
              target="_blank">aquí</a>
          </div>
        </div>
        
        <p>
        La cobertura es válida ante cualquier imprevisto y/o urgencia 
        que usted sufra a más de 100km de  su domicilio habitual y permanente. 
        Para más información sobre la cobertura contáctese con Universal Assistance 
        al 0800-222-1939 / 0800-222-8565 o a través de la APP.
        </p>
        
        <p>
          Se encuentra disponible en <a className="links-inner-faqs text-blue ua-links"
            href="https://play.google.com/store/apps/details?id=com.mobilenik.universalAssistance&hl=es_AR&gl=US"
            target="_blank"
            rel="noopener noreferrer"
          >Play Store</a> y <a className="links-inner-faqs text-blue ua-links"
            href="https://apps.apple.com/ar/app/universal-assistance/id1005237356"
            target="_blank"
            rel="noopener noreferrer"
          >Apple Store</a> la APP de Universal Assistance con la que podrá visualizar su 
          cobertura médica, solicitar asistencia, chatear con un operador, acceder a telemedicina, y otras herramientas.
        </p>

        <p>
          Puede descargar la APP de Universal Assistance desde aquí: &nbsp;&nbsp;
          <a
            className="links-inner-faqs text-blue ua-links"
            href="https://play.google.com/store/apps/details?id=com.mobilenik.universalAssistance&hl=es_AR&gl=US"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={appuaa} className=" " alt="" style={{ height: 35}} /> 
          </a>
          
          <a
            className="links-inner-faqs text-blue ua-links"
            href="https://apps.apple.com/ar/app/universal-assistance/id1005237356"
            target="_blank"
            rel="noopener noreferrer"
          ><img src={appuai} className=" " alt="" style={{ height: 35}} />
          </a>

        </p>

        <ul className="ua_ul">
          <li className="ua_list">
            <div className="iconos-ua-wrapper">
              <div className="iconos-ua-icon">
                <img src={Emergencias} className="icons_ua" alt="" />
              </div>
              <div>Emergencias médicas las 24hs.</div>
            </div>
          </li>
          <li className="ua_list">
            <div className="iconos-ua-wrapper">
              <div className="iconos-ua-icon">
                <img
                  src={Ambulancias}
                  className="icons_ua icons_ua_bigger"
                  alt=""
                />
              </div>
              <div>Visitas médicas a domicilio.</div>
            </div>
          </li>
          <li className="ua_list">
            <div className="iconos-ua-wrapper">
              <div className="iconos-ua-icon">
                <img
                  src={Pildoras}
                  className="icons_ua icons_ua_bigger"
                  alt=""
                />
              </div>
              <div>Descuento en farmacias.</div>
            </div>
          </li>
          <li className="ua_list">
            <div className="iconos-ua-wrapper">
              <div className="iconos-ua-icon">
                <img src={Avion} className="icons_ua icons_ua_bigger" alt="" />
              </div>
              <div>Cobertura para el viajero.</div>
            </div>
          </li>
        </ul>

      </Col>

      <Col xs={12}>
        <ColoredLine></ColoredLine>
        <Accordion>
          <div>
            <CardHeader className="faqs__card-header">
              <Accordion.Toggle
                as={Button}
                variant="link"
                eventKey="0"
                className="faqs__titles w-100"
              >
                Cómo usar la APP de Universal Assistance
                <img src={CaretDown} alt="caret down" className="pl-2" />
              </Accordion.Toggle>
            </CardHeader>

            <Accordion.Collapse eventKey="0">
              <CardBody className="body-wrapper">
                <ul className="list-unstyled">
                  <li className="mb-2 d-flex align-items-center">
                    <img src={Check} alt="check icon" className="icon-align pr-2" />
                    <span>
                      En el menú de inicio ir a Mi Cobertura
                    </span>
                  </li>

                  <li className="mb-2 d-flex align-items-center">
                    <img src={Check} alt="check icon" className="icon-align pr-2" />
                    <span>
                      Seleccionar tipo de documento
                    </span>
                  </li>

                  <li className="mb-2 d-flex align-items-center">
                    <img src={Check} alt="check icon" className="icon-align pr-2" />
                    <span>
                      Poner el número del documento seleccionado previamente
                    </span>
                  </li>

                  <li className="mb-2 d-flex align-items-center">
                    <img src={Check} alt="check icon" className="icon-align pr-2" />
                    <span>
                      Presionar acceder
                    </span>
                  </li>

                  <li className="mb-2 d-flex align-items-center">
                    <img src={Check} alt="check icon" className="icon-align pr-2" />
                    <span>
                      Ir a mi cobertura
                    </span>
                  </li>

                </ul>

                <p>
                  Este proceso debe realizarse para cada uno de los asociados que 
                  deseen obtener esta constancia, por lo que deberá cerrar la sesión e 
                  iniciarla con el otro D.N.I. solicitante.
                </p>

                <p>
                  Tenga en cuenta que estos procedimientos son obligatorios para que 
                  pueda acceder a la cobertura médica en tránsito. 
                  No se admiten reintegros por prestaciones y/o gastos médicos sanatoriales 
                  en ningún caso en que no se cumpla con el procedimiento previo 
                  de comunicación a Universal Assistance y/o no se sigan las directivas que ellos indican.
                </p>
                
              </CardBody>
            </Accordion.Collapse>
            
          </div>
        </Accordion>

        <p>&nbsp;</p>
        <p>&nbsp;</p>
      </Col>
      
    </PageWrapper>
  );
};

export default Content;
