import React from "react";
import { Link } from "react-router-dom";

import ArrowBlanca from "../assets/img/arrow-blanca.svg";
import ArrowGrey from "../assets/img/arrow-grey.svg";
import ArrowAzul from "../assets/img/arrow-azul.svg";
const Button = ({ children, url, type, external }) => {
  switch (type) {
    case "orange": {
      if (external) {
        return (
          <a
            href={url}
            className="btn-orange"
            target="_blank"
            rel="noopener noreferrer"
          >
            {children}{" "}
          </a>
        );
      }

      return (
        <Link to={url} className="btn-orange">
          {children}{" "}
          <img
            src={ArrowAzul}
            alt="arrow right"
            className="home-slider_info__arrow"
          />
        </Link>
      );
    }

    case "orange-outline": {
      return (
        <Link to={url} className="btn-orange_outline">
          {children}
        </Link>
      );
    }

    case "white-outline": {
      return (
        <Link to={url} className="btn-white-outline mx-1 mb-2">
          {children}{" "}
          <img
            src={ArrowBlanca}
            alt="arrow right"
            className="home-slider_info__arrow"
          />
        </Link>
      );
    }

    case "grey-outline": {
      return (
        <>
          {external ? (
            <a
              href={url}
              className="btn-grey-outline mx-1"
              target="_blank"
              rel="noopener noreferrer"
            >
              {children}{" "}
              <img
                src={ArrowGrey}
                alt="arrow right"
                className="home-slider_info__arrow"
              />
            </a>
          ) : (
            <Link to={url} className="btn-grey-outline mx-1">
              {children}{" "}
              <img
                src={ArrowGrey}
                alt="arrow right"
                className="home-slider_info__arrow"
              />
            </Link>
          )}
        </>
      );
    }

    default: {
      return null;
    }
  }
};

export default Button;
